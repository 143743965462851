@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");

/* top UI atyles */

label {  cursor: pointer; padding: 2px 4px 2px 4px; }
label span{ padding: 0px 4px 0px 8px; position: relative; vertical-align: middle; bottom: 2px; }

#topTitle {
    display: flex;
    position: absolute;
    width: 100%;
    padding: 0 9px 0 16px;
    font-size: 1.1rem;
    height: 47px;
    min-height: 47px;
    /* opacity: 0.85; */
    text-align: left; 
    vertical-align: middle; 
    justify-content: flex-start; 
    align-items: center; 
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
#topTitle span.title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#topUIToolbarExpanded{
    /* display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
}

#topUIToolbarExpanded .MuiIconButton-root{
    border-radius: 0;
    width: 30px;
    height: 30px;
    padding: 0;
    color: #5a5a5a;
    background-color: transparent;
}
#topUIToolbarExpanded .MuiIconButton-root svg{
    width: 1em;
    height: 1em;
    fill: #5a5a5a;
    stroke: #5a5a5a;
}

#topUIToolbarExpanded .MuiIconButton-root:hover, #topUIToolbarExpanded .MuiIconButton-root svg:hover{
    color: #E60000;
    background-color: transparent;
    fill: #E60000;
    stroke: #E60000;
}      
#topUIToolbarExpanded .MuiIconButton-root svg.active {
    color: #E60000;
    fill: #E60000;
    stroke: #E60000;
}
.svg_diagram{
    margin-top: 45px;
}
.checkboxItems {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

.listIconItem1 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    padding-left: 5px;
    padding-right: 5px;
}

.MuiSlider-rail, .MuiSlider-track{
    background-color: #fff !important;
}
.floatItem {
    float: left;
    max-width: 50% !important;
    min-width: 47%;
    padding: 0px 6px;
}
.listIconItem .MuiListItemIcon-root{
    min-width: auto !important;
}

.listIconItem .MuiListItemIcon-root:hover{
    color: #E60000;
}

.listIconItem.heading{
    padding-bottom: 0;
    cursor: text;
}
.listIconItem.heading:hover{
    background: none;
}
.listIconItem.heading .MuiListItemText-root{
    margin-bottom: 0;
}
.checkbox{
    min-width: auto !important;
}
.checkbox .MuiIconButton-root {
    padding: 0 5px 0 0;
}
.checkbox svg.MuiSvgIcon-root{
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
}
.show_counters{
    display: flex;
    align-items: center;    
    justify-content: center;
    flex-basis: 10px !important;
}
.show_counters.custom-width{
    flex-basis: 0px !important;
}
.show_label{
    margin-left: 20px;
    margin-right: 10px;
}
#topUIToolbarExpanded .uspto_logo{
    width: 75px;
}
.empty:hover{
    background: none !important;
}
.uspto_logo_container{
    display: flex;
    align-items: center;
}

#bottomUIToolbarContainer {
    width: 100%;
    height: 32px;
    position: sticky;
    bottom: 0;
    min-width: 100vw;
}



#bottomUIToolbarExpanded {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    height: 32px;
    grid-template-columns: repeat(18, max-content) repeat(3, 1fr) repeat(3, max-content);
    grid-row-gap: 0px;
    background-color: #424242;
    box-shadow: 0 0px 5px 0 #2b2b2b;
    font-size: 12px;
    color: #FFFFFF;
    opacity: 0.85;
    
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

}
#bottomUIToolbarClosed:hover {
  background-color: rgba(18 ,18 ,18, 1);
  transform: translateX(-15px);
}
#bottomUIToolbarClosed {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-row-gap: 0px;
    width: fit-content;
    height: 32px;
    background-color: #111111;
    background-color: rgba(18 ,18 ,18, 0.5);
    font-size: 14px;
    color: #FFFFFF;
    opacity: 0.85;
    border-radius: 0 50px 50px 0;
    transform: translateX(-30px);
    transition: .2s;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */   
    
}

.toolbarUIElement { 
    display: flex; 
    cursor: pointer; 
    text-align: center; 
    vertical-align:  middle; 
    line-height: 32px; 
    justify-content: center; 
    align-items: center; 
}
.toolbarUIElement svg {    position: unset !important;  }
.toolbarUIGap { 
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.toolbarUIPopupOn { 
    display: block; 
}

.toolbarUIPopupOff{ 
    display: none; 
}

.toolbarUIQuantative {
    text-align: center; 
    vertical-align: middle; 
    line-height: 34px;
    justify-content: flex-start; 
    align-items: center;
    min-width: 3.5rem;
}

.toolbarUIList { 
    text-align: left; 
    vertical-align: middle; 
    line-height: 1.5rem; 
    justify-content: flex-start; 
    align-items: center; 
}

.toolbarUIPopup {
    display: none;
    position: absolute;
    padding: 0.5rem;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 1.5;
    background-color: #111111;
    opacity: 0.85;
    text-align: left; 
    justify-content: flex-start; 
    align-items: flex-start;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
 
}
#patentrackDiagramDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

#patentrackDiagramDiv svg { 
    flex: 1;    
    overflow: initial;
}

.diagram-container {
    margin-top: 45px;
}

#patentrackDiagramDiv .toolbarUIPopup ui,#patentrackDiagramDiv li {  
    list-style-type: none;  
    text-indent: -2.4rem;
}
.wrapText tspan:nth-child(3){ 
    display: none;
}
div.tooltip_title {
    position: absolute;			
    text-align: left;			
    /* margin: 0 auto; */
    text-align: left;
    width: auto;
    max-width: 500px;
    border: 0 !important;	
    border-radius: 0 !important;	
    padding: 5px 10px;
    pointer-events: none;
    font-weight: inherit; 
    font-size: 12.25px;
    white-space: normal;
}
.tooltipContainer {
    display: flex;
    justify-content: center;
}
.tooltipContainer  .svg-inline--fa.fa-w-14{
    /* width: 1rem;
    height: 1rem; */
}
.tooltip {
    font-size: 1rem;
    border-radius: 0;
}
.uspto_logo{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}
.uspto_logo img{
    width: 40px;
}
.zoom_container{
    min-width: 76px !important; 
}
/*
#toobarUIGap0 { grid-area: 1 / 1 / 2 / 2; text-align: center; justify-content: center; align-items: center; }
#expandOnOff { grid-area: 1 / 2 / 2 / 3; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap1 { grid-area: 1 / 3 / 2 / 4; text-align: center; justify-content: center; align-items: center; }
#fastBackward { grid-area: 1 / 4 / 2 / 5; text-align: center; justify-content: center; align-items: center; }
#fastForward { grid-area: 1 / 5 / 2 / 6; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap2 { grid-area: 1 / 6 / 2 / 7; text-align: center; justify-content: center; align-items: center; }
#prevAssignment { grid-area: 1 / 7 / 2 / 8; text-align: center; justify-content: center; align-items: center; }
#assignmentQuantative { grid-area: 1 / 8 / 2 / 9; text-align: center; justify-content: center; align-items: center; }
#nextAssignment { grid-area: 1 / 9 / 2 / 10; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap3 { grid-area: 1 / 10 / 2 / 11; text-align: center; justify-content: center; align-items: center; }
#prevAssignee { grid-area: 1 / 11 / 2 / 12; text-align: center; justify-content: center; align-items: center; }
#assigneeQuantative { grid-area: 1 / 12 / 2 / 13; text-align: center; justify-content: center; align-items: center; }
#nextAssignee { grid-area: 1 / 13 / 2 / 14; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap4 { grid-area: 1 / 14 / 2 / 15; text-align: center; justify-content: center; align-items: center; }
#PatentrackMultiCheckbox { grid-area: 1 / 15 / 2 / 16; text-align: center; justify-content: center; align-items: center; }
#PatentrackLegend { grid-area: 1 / 16 / 2 / 17; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap5 { grid-area: 1 / 17 / 2 / 18; text-align: center; justify-content: center; align-items: center; }
#horizontalExtenderA { grid-area: 1 / 18 / 2 / 19; text-align: center; justify-content: center; align-items: center; }
#horizontalExtenderB { grid-area: 1 / 19 / 2 / 20; text-align: center; justify-content: center; align-items: center; }
#zoomDiagram { grid-area: 1 / 20 / 2 / 21; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap6 { grid-area: 1 / 21 / 2 / 22; text-align: center; justify-content: center; align-items: center; }
#shareDiagram { grid-area: 1 / 22 / 2 / 23; text-align: center; justify-content: center; align-items: center; }
*/



