@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
/* top UI atyles */

label {  cursor: pointer; padding: 2px 4px 2px 4px; }
label span{ padding: 0px 4px 0px 8px; position: relative; vertical-align: middle; bottom: 2px; }

#topTitle {
    display: flex;
    position: absolute;
    width: 100%;
    padding: 0 9px 0 16px;
    font-size: 1.1rem;
    height: 47px;
    min-height: 47px;
    /* opacity: 0.85; */
    text-align: left; 
    vertical-align: middle; 
    justify-content: flex-start; 
    align-items: center; 
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
#topTitle span.title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#topUIToolbarExpanded{
    /* display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
}

#topUIToolbarExpanded .MuiIconButton-root{
    border-radius: 0;
    width: 30px;
    height: 30px;
    padding: 0;
    color: #5a5a5a;
    background-color: transparent;
}
#topUIToolbarExpanded .MuiIconButton-root svg{
    width: 1em;
    height: 1em;
    fill: #5a5a5a;
    stroke: #5a5a5a;
}

#topUIToolbarExpanded .MuiIconButton-root:hover, #topUIToolbarExpanded .MuiIconButton-root svg:hover{
    color: #E60000;
    background-color: transparent;
    fill: #E60000;
    stroke: #E60000;
}      
#topUIToolbarExpanded .MuiIconButton-root svg.active {
    color: #E60000;
    fill: #E60000;
    stroke: #E60000;
}
.svg_diagram{
    margin-top: 45px;
}
.checkboxItems {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

.listIconItem1 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    padding-left: 5px;
    padding-right: 5px;
}

.MuiSlider-rail, .MuiSlider-track{
    background-color: #fff !important;
}
.floatItem {
    float: left;
    max-width: 50% !important;
    min-width: 47%;
    padding: 0px 6px;
}
.listIconItem .MuiListItemIcon-root{
    min-width: auto !important;
}

.listIconItem .MuiListItemIcon-root:hover{
    color: #E60000;
}

.listIconItem.heading{
    padding-bottom: 0;
    cursor: text;
}
.listIconItem.heading:hover{
    background: none;
}
.listIconItem.heading .MuiListItemText-root{
    margin-bottom: 0;
}
.checkbox{
    min-width: auto !important;
}
.checkbox .MuiIconButton-root {
    padding: 0 5px 0 0;
}
.checkbox svg.MuiSvgIcon-root{
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
}
.show_counters{
    display: flex;
    align-items: center;    
    justify-content: center;
    flex-basis: 10px !important;
}
.show_counters.custom-width{
    flex-basis: 0px !important;
}
.show_label{
    margin-left: 20px;
    margin-right: 10px;
}
#topUIToolbarExpanded .uspto_logo{
    width: 75px;
}
.empty:hover{
    background: none !important;
}
.uspto_logo_container{
    display: flex;
    align-items: center;
}

#bottomUIToolbarContainer {
    width: 100%;
    height: 32px;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    min-width: 100vw;
}



#bottomUIToolbarExpanded {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    height: 32px;
    grid-template-columns: repeat(18, -webkit-max-content) repeat(3, 1fr) repeat(3, -webkit-max-content);
    grid-template-columns: repeat(18, max-content) repeat(3, 1fr) repeat(3, max-content);
    grid-row-gap: 0px;
    background-color: #424242;
    box-shadow: 0 0px 5px 0 #2b2b2b;
    font-size: 12px;
    color: #FFFFFF;
    opacity: 0.85;
    
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

}
#bottomUIToolbarClosed:hover {
  background-color: rgba(18 ,18 ,18, 1);
  -webkit-transform: translateX(-15px);
          transform: translateX(-15px);
}
#bottomUIToolbarClosed {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-row-gap: 0px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 32px;
    background-color: #111111;
    background-color: rgba(18 ,18 ,18, 0.5);
    font-size: 14px;
    color: #FFFFFF;
    opacity: 0.85;
    border-radius: 0 50px 50px 0;
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    transition: .2s;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */   
    
}

.toolbarUIElement { 
    display: flex; 
    cursor: pointer; 
    text-align: center; 
    vertical-align:  middle; 
    line-height: 32px; 
    justify-content: center; 
    align-items: center; 
}
.toolbarUIElement svg {    position: unset !important;  }
.toolbarUIGap { 
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.toolbarUIPopupOn { 
    display: block; 
}

.toolbarUIPopupOff{ 
    display: none; 
}

.toolbarUIQuantative {
    text-align: center; 
    vertical-align: middle; 
    line-height: 34px;
    justify-content: flex-start; 
    align-items: center;
    min-width: 3.5rem;
}

.toolbarUIList { 
    text-align: left; 
    vertical-align: middle; 
    line-height: 1.5rem; 
    justify-content: flex-start; 
    align-items: center; 
}

.toolbarUIPopup {
    display: none;
    position: absolute;
    padding: 0.5rem;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 1.5;
    background-color: #111111;
    opacity: 0.85;
    text-align: left; 
    justify-content: flex-start; 
    align-items: flex-start;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
 
}
#patentrackDiagramDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

#patentrackDiagramDiv svg { 
    flex: 1 1;    
    overflow: initial;
}

.diagram-container {
    margin-top: 45px;
}

#patentrackDiagramDiv .toolbarUIPopup ui,#patentrackDiagramDiv li {  
    list-style-type: none;  
    text-indent: -2.4rem;
}
.wrapText tspan:nth-child(3){ 
    display: none;
}
div.tooltip_title {
    position: absolute;			
    text-align: left;			
    /* margin: 0 auto; */
    text-align: left;
    width: auto;
    max-width: 500px;
    border: 0 !important;	
    border-radius: 0 !important;	
    padding: 5px 10px;
    pointer-events: none;
    font-weight: inherit; 
    font-size: 12.25px;
    white-space: normal;
}
.tooltipContainer {
    display: flex;
    justify-content: center;
}
.tooltipContainer  .svg-inline--fa.fa-w-14{
    /* width: 1rem;
    height: 1rem; */
}
.tooltip {
    font-size: 1rem;
    border-radius: 0;
}
.uspto_logo{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}
.uspto_logo img{
    width: 40px;
}
.zoom_container{
    min-width: 76px !important; 
}
/*
#toobarUIGap0 { grid-area: 1 / 1 / 2 / 2; text-align: center; justify-content: center; align-items: center; }
#expandOnOff { grid-area: 1 / 2 / 2 / 3; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap1 { grid-area: 1 / 3 / 2 / 4; text-align: center; justify-content: center; align-items: center; }
#fastBackward { grid-area: 1 / 4 / 2 / 5; text-align: center; justify-content: center; align-items: center; }
#fastForward { grid-area: 1 / 5 / 2 / 6; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap2 { grid-area: 1 / 6 / 2 / 7; text-align: center; justify-content: center; align-items: center; }
#prevAssignment { grid-area: 1 / 7 / 2 / 8; text-align: center; justify-content: center; align-items: center; }
#assignmentQuantative { grid-area: 1 / 8 / 2 / 9; text-align: center; justify-content: center; align-items: center; }
#nextAssignment { grid-area: 1 / 9 / 2 / 10; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap3 { grid-area: 1 / 10 / 2 / 11; text-align: center; justify-content: center; align-items: center; }
#prevAssignee { grid-area: 1 / 11 / 2 / 12; text-align: center; justify-content: center; align-items: center; }
#assigneeQuantative { grid-area: 1 / 12 / 2 / 13; text-align: center; justify-content: center; align-items: center; }
#nextAssignee { grid-area: 1 / 13 / 2 / 14; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap4 { grid-area: 1 / 14 / 2 / 15; text-align: center; justify-content: center; align-items: center; }
#PatentrackMultiCheckbox { grid-area: 1 / 15 / 2 / 16; text-align: center; justify-content: center; align-items: center; }
#PatentrackLegend { grid-area: 1 / 16 / 2 / 17; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap5 { grid-area: 1 / 17 / 2 / 18; text-align: center; justify-content: center; align-items: center; }
#horizontalExtenderA { grid-area: 1 / 18 / 2 / 19; text-align: center; justify-content: center; align-items: center; }
#horizontalExtenderB { grid-area: 1 / 19 / 2 / 20; text-align: center; justify-content: center; align-items: center; }
#zoomDiagram { grid-area: 1 / 20 / 2 / 21; text-align: center; justify-content: center; align-items: center; }
#toobarUIGap6 { grid-area: 1 / 21 / 2 / 22; text-align: center; justify-content: center; align-items: center; }
#shareDiagram { grid-area: 1 / 22 / 2 / 23; text-align: center; justify-content: center; align-items: center; }
*/




@charset "UTF-8";

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/77156710-6a58-4606-b189-b4185e75967b.00f402b0.woff2) format("woff2"), url(/static/media/3d5260a1-e4cd-4567-80ed-69d23c40355f.e3b88962.woff) format("woff");	
	font-weight: 300;
	font-style: normal
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/77caabd3-1877-4634-85c8-8e398a093b99.e541e48f.woff2) format("woff2"), url(/static/media/e388ac99-8c6a-4451-8690-1d15b4d45adb.d7a47c7d.woff) format("woff");
	font-weight: 400;
	font-style: normal
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/014f2daa-c310-4a36-b9fd-79a8e0c48d44.b67b4d9f.woff2) format("woff2"), url(/static/media/12b00842-ec20-4c7f-aa72-802fb00f6cc4.0307d586.woff) format("woff");
	font-weight: 400;
	font-style: italic
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/e78b17bb-11fb-4860-8d66-4ee0d0c1e117.4afec75d.woff2) format("woff2"), url(/static/media/d4ffabb3-dd7c-472a-bdfb-6700383c6354.e72348ef.woff) format("woff");
	font-weight: 700;
	font-style: normal
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/77156710-6a58-4606-b189-b4185e75967b-ext.5b015023.woff2) format("woff2"), url(/static/media/3d5260a1-e4cd-4567-80ed-69d23c40355f-ext.78e8e790.woff) format("woff");
	font-weight: 300;
	font-style: normal;
	unicode-range: U+0100-017F, U+0180-024F, U+1E00-1EFF, U+02B0-02FF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/77caabd3-1877-4634-85c8-8e398a093b99-ext.265383e5.woff2) format("woff2"), url(/static/media/e388ac99-8c6a-4451-8690-1d15b4d45adb-ext.2b4b146c.woff) format("woff");
	font-weight: 400;
	font-style: normal;
	unicode-range: U+0100-017F, U+0180-024F, U+1E00-1EFF, U+02B0-02FF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/014f2daa-c310-4a36-b9fd-79a8e0c48d44-ext.9c7501f5.woff2) format("woff2"), url(/static/media/12b00842-ec20-4c7f-aa72-802fb00f6cc4-ext.be054f9a.woff) format("woff");
	font-weight: 400;
	font-style: italic;
	unicode-range: U+0100-017F, U+0180-024F, U+1E00-1EFF, U+02B0-02FF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/9851da0a-2481-4687-bbeb-ed4ab170dc38-ext.f05ac5a8.woff2) format("woff2"), url(/static/media/cebce072-9561-4c6d-8c89-f0cefec63289-ext.5cdffc76.woff) format("woff");
	font-weight: 700;
	font-style: normal;
	unicode-range: U+0100-017F, U+0180-024F, U+1E00-1EFF, U+02B0-02FF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/e78b17bb-11fb-4860-8d66-4ee0d0c1e117-ext.c425fb3a.woff2) format("woff2"), url(/static/media/d4ffabb3-dd7c-472a-bdfb-6700383c6354-ext.ce33458b.woff) format("woff");
	font-weight: 700;
	font-style: normal;
	unicode-range: U+0100-017F, U+0180-024F, U+1E00-1EFF, U+02B0-02FF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/77156710-6a58-4606-b189-b4185e75967b-greek.d9a4a441.woff2) format("woff2"), url(/static/media/3d5260a1-e4cd-4567-80ed-69d23c40355f-greek.ff7268ae.woff) format("woff");
	font-weight: 300;
	font-style: normal;
	unicode-range: U+0370-03FF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/77caabd3-1877-4634-85c8-8e398a093b99-greek.22355e60.woff2) format("woff2"), url(/static/media/e388ac99-8c6a-4451-8690-1d15b4d45adb-greek.30648e54.woff) format("woff");
	font-weight: 400;
	font-style: normal;
	unicode-range: U+0370-03FF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/014f2daa-c310-4a36-b9fd-79a8e0c48d44-greek.4ede03c0.woff2) format("woff2"), url(/static/media/12b00842-ec20-4c7f-aa72-802fb00f6cc4-greek.9856e73b.woff) format("woff");
	font-weight: 400;
	font-style: italic;
	unicode-range: U+0370-03FF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/e78b17bb-11fb-4860-8d66-4ee0d0c1e117-greek.dba46e38.woff2) format("woff2"), url(/static/media/d4ffabb3-dd7c-472a-bdfb-6700383c6354-greek.f4f4ea95.woff) format("woff");
	font-weight: 700;
	font-style: normal;
	unicode-range: U+0370-03FF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/174d458a-81e0-4174-9473-35e3bf0a613c.50fe0240.woff2) format("woff2"), url(/static/media/57a79aa3-9b06-4ba7-a9a4-2b766d826ecf.5cfd714b.woff) format("woff");
	font-weight: 300;
	font-style: normal;
	unicode-range: U+0400-04FF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/7db1f672-3a8f-4d19-9c49-7f61aed450b5.bac188b4.woff2) format("woff2"), url(/static/media/4ab86b35-c0c2-42b5-98ad-4b6eba66b197.594c5cdf.woff) format("woff");
	font-weight: 400;
	font-style: normal;
	unicode-range: U+0400-04FF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/b17468ea-cf53-4635-984b-4d930a68ed4d.1584837a.woff2) format("woff2"), url(/static/media/4d1d0d0d-9ea6-4117-901f-8b32ca1ab936.7ffc334a.woff) format("woff");
	font-weight: 400;
	font-style: italic;
	unicode-range: U+0400-04FF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/40d36b4a-60c6-460a-bf43-4c948c23563e.5816586f.woff2) format("woff2"), url(/static/media/45b78f45-e639-4836-8612-e0892e120f14.26abda57.woff) format("woff");
	font-weight: 700;
	font-style: normal;
	unicode-range: U+0400-04FF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/281f890c-8412-4ee3-84ed-8b5d062d2ab8.5a5c4ac8.woff2) format("woff2"), url(/static/media/5729f02e-f6b0-4f35-8ee5-c2cffa65fa76.dd23a4e9.woff) format("woff");
	font-weight: 300;
	font-style: normal;
	unicode-range: U+10A0-10FF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/281f890c-8412-4ee3-84ed-8b5d062d2ab8.5a5c4ac8.woff2) format("woff2"), url(/static/media/5729f02e-f6b0-4f35-8ee5-c2cffa65fa76.dd23a4e9.woff) format("woff");
	font-weight: 400;
	font-style: normal;
	unicode-range: U+10A0-10FF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/2200dfff-da50-40b0-bc12-5e4b872a1998.56b04aec.woff2) format("woff2"), url(/static/media/dc10b3bd-5076-4df5-a5f5-e5961f4a6938.e17abc84.woff) format("woff");
	font-weight: 700;
	font-style: normal;
	unicode-range: U+10A0-10FF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/2a1ae9a5-b6b5-405c-b660-bbdf1b356952.deec157e.woff2) format("woff2"), url(/static/media/a8aeea1b-1a9d-45b7-8ad9-7c71824599e2.65a7a2f9.woff) format("woff");
	font-weight: 300;
	font-style: normal;
	unicode-range: U+0600-06FF, U+FB50-FDFF, U+FE70-FEFF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/6ea5fa46-5311-450b-8744-288a30c55348.eea8d6d7.woff2) format("woff2"), url(/static/media/d9e4040d-32ff-4a1c-ac04-927a781da1f5.548dcaca.woff) format("woff");
	font-weight: 400;
	font-style: normal;
	unicode-range: U+0600-06FF, U+FB50-FDFF, U+FE70-FEFF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/97694c53-4e94-4f9e-969b-a148adfcdcfd.e55891f8.woff2) format("woff2"), url(/static/media/8b01637a-f445-4f10-92ea-b84a355f7690.2bd3ab23.woff) format("woff");
	font-weight: 700;
	font-style: normal;
	unicode-range: U+0600-06FF, U+FB50-FDFF, U+FE70-FEFF
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/31da4b04-f98a-4b5f-b545-a31d26da99e5.b379869f.woff2) format("woff2"), url(/static/media/d98fb015-7ef6-404f-a58a-5c9242d79770.c960580e.woff) format("woff");
	font-weight: 300;
	font-style: normal;
	unicode-range: U+0590-05FF, U+FB00-FB4F
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/31da4b04-f98a-4b5f-b545-a31d26da99e5.b379869f.woff2) format("woff2"), url(/static/media/d98fb015-7ef6-404f-a58a-5c9242d79770.c960580e.woff) format("woff");
	font-weight: 400;
	font-style: normal;
	unicode-range: U+0590-05FF, U+FB00-FB4F
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/32a2c5cf-6736-44a6-a276-49ba7e030944.26d6ef19.woff2) format("woff2"), url(/static/media/fa71df11-7b19-4baf-8ff7-3537dea718f0.d0557337.woff) format("woff");
	font-weight: 400;
	font-style: italic;
	unicode-range: U+0590-05FF, U+FB00-FB4F
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/a9eaf4d3-6427-42df-9306-3ea1270f7b1a.5bb1c0af.woff2) format("woff2"), url(/static/media/f4a085c3-1c64-4fc0-a598-26f3e658c2b0.3285c445.woff) format("woff");
	font-weight: 700;
	font-style: normal;
	unicode-range: U+0590-05FF, U+FB00-FB4F
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/94aa531e-7746-4df0-bb6e-349891f2eda5.fd862f58.woff2) format("woff2"), url(/static/media/121524c1-8d82-4155-bfb3-fd2f15f09e93.ea3da238.woff) format("woff");
	font-weight: 300;
	font-style: normal;
	unicode-range: U+0900-097F
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/3ae1e25e-3aa6-4061-a016-a079159f9d65.f284e1cb.woff2) format("woff2"), url(/static/media/f1799750-0952-403f-8108-b2402eed0f62.d90b3fe2.woff) format("woff");
	font-weight: 400;
	font-style: normal;
	unicode-range: U+0900-097F
}

@font-face {
	font-family: "Avenir Next";
	src: url(/static/media/41331c3c-3759-4462-8695-33c9a21b6a5b.be40fb3b.woff2) format("woff2"), url(/static/media/31e0c094-e345-4a54-a797-d5f1a5885572.6a50b2f7.woff) format("woff");
	font-weight: 700;
	font-style: normal;
	unicode-range: U+0900-097F
}

@font-face {
	font-family: "SST Vietnamese";
	src: url(/static/media/c4cc9032-7eee-4a6e-ae8b-f384b1349bcf.d310a85c.woff2) format("woff2"), url(/static/media/1b3078ef-2971-4c95-b6ca-13ab528758cb.8c2b03e3.woff) format("woff");
	font-weight: 300;
	font-style: normal
}

@font-face {
	font-family: "SST Vietnamese";
	src: url(/static/media/c1905e2e-a1cb-49de-9bb0-ce3c5ffc85ae.6039d7ca.woff2) format("woff2"), url(/static/media/341bcc5e-7ac0-44ff-819d-5887892eab1b.7d4ad38c.woff) format("woff");
	font-weight: 400;
	font-style: normal
}

@font-face {
	font-family: "SST Vietnamese";
	src: url(/static/media/4daa2125-53c6-4da8-9614-8a1049eaccc2.9cf24796.woff2) format("woff2"), url(/static/media/0763eab1-d6ed-4c73-afb0-895f930df099.c15c72e5.woff) format("woff");
	font-weight: 700;
	font-style: normal
}
html {
  font-size: 75%;
}

body {
  font-family: "Avenir Next W01", "Avenir Next W00", "Avenir Next", Avenir,
    "Helvetica Neue", sans-serif !important;
  margin: 0 auto;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  width: 100%;
}

span {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

span {
  word-break: break-word;
}

.MuiTableCell-stickyHeader {
  background-color: #222 !important;
}

.MuiTab-root {
  text-transform: initial !important;
  font-weight: inherit !important;
}

.MuiBackdrop-root{
  display: none !important;
}

.MuiDialog-root {
  height: 1px;
  width: 1px;  
}

.MuiDialog-container{
  width: 450px;
  height: auto !important;
  position: absolute; 
}

.MuiDialog-paperScrollPaper{
  max-height: calc(100% - 0px) !important
}

#illustration_modal {
  display: none; /* Hidden by default */
  /*position: fixed;*/ /* Stay in place */
  /*Changes by Vikas*/
  position: absolute;
  z-index: 1000004; /* Sit on top */
  /*padding-top: 100px; */ /* Location of the box */

  right: 0;
  top: 0;
  width: 33.333333%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

#illustration_modal.show {
  display: block;
}

#assignment_details .title {
  /*font-size: 2rem;
  text-align: center;
  line-height: 2.5;
  font-weight: 500;
  border-bottom: 1px solid #eceeef;
  background: #222;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;*/
  text-align: left;
  font-weight: 500;
  border-bottom: 1px solid #888;
  background: #222;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

#assignment_details .p1 {
  padding: 1rem;
}

#assignment_details table,
#assignment_details tbody,
#assignment_details tr {
  width: 100%;
}

#assignment_details tr {
  border-bottom: 1px solid black;
}

#assignment_details td {
  /*font-size: 1.5rem;
  line-height: 1.5;*/
  padding: 5px 10px;
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /*-webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s*/
}

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: inherit !important;
  color: #e60000;
}

.excel-green {
  color: #1fa463;
}

.doc-blue {
  color: #4688f4;
}

.ppt-yellow {
  color: #ffd04b;
}

.pdf-red {
  color: #ff0000;
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

#illustration_modal .modal-content {
  width: 542px;
  /*changes by vikas*/
  position: absolute;
  right: 10px;
  top: 68px;
  background: #000;
  color: #bdbdbd;
}

.d-none {
  display: none;
}

.red {
  color: #e60000 !important;
  border-color: #e60000 !important;
}
.blue {
  color: #2493f2 !important;
  border-color: #2493f2 !important;
}
.orange {
  color: #ffaa00 !important;
  border-color: #ffaa00 !important;
}
.green {
  color: #70a800 !important;
  border-color: #70a800 !important;
}
.white {
  color: white !important;
}
.grey {
  color: #bdbdbd !important;
}

.list_companies .MuiToolbar-regular {
  display: none;
}

.active-not-leaf-node-wrapper,
.active-node-wrapper {
  color: #e60000;
  background-color: inherit;
}

.node-wrapper .MuiSvgIcon-root,
.active-not-leaf-node-wrapper .MuiSvgIcon-root {
  position: relative;
  top: 5px;
}

#assignment_details .close {
  color: white;
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
}

#assignment_details .close:hover,
#assignment_details .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

#pdfViewer {
  position: relative;
  width: 536px;
  height: 628px;
  z-index: 1000;
  display: none;
}

.MuiDialog-root {
  z-index: 9999 !important;
}

.vis-foreground .vis-group,
.vis-time-axis .vis-grid.vis-vertical {
  border: 0px !important;
}

.vis-label.vis-nested-group.vis-group-level-1,
.vis-itemset .vis-background,
.vis-itemset .vis-foreground,
.vis-label.vis-nested-group.vis-group-level-1,
.vis-itemset .vis-background,
.vis-itemset .vis-foreground {
  background-color: #222222 !important;
}

.vis-labelset .vis-label,
.vis-timeline {
  color: #d6d6d6 !important;
  border-bottom: 1px solid #5c5c5c !important;
}

.vis-timeline {
  border: 1px solid #5c5c5c !important;
}

.vis-labelset .vis-label {
  border-right: 0px solid #5c5c5c !important;
}

.vis-time-axis .vis-text,
.vis-item {
  color: #d6d6d6 !important;
}

.vis-timeline {
  background-color: #222222 !important;
}
.vis-tooltip p {
  margin: 5px 0 0 0;
}

.vis-content {
  top: 0 !important;
}

.vis-nested-group {
  min-height: 22%;
}
.vis-ltr .vis-label.vis-nested-group.vis-group-level-1 .vis-inner {
  padding-left: 5px !important;
}
.vis-ltr .vis-label.vis-nested-group.vis-group-level-2 .vis-inner {
  padding-left: 20px !important;
}

#timeline .vis-panel.vis-center,
#timeline .vis-panel.vis-left,
#timeline .vis-panel.vis-right,
#timeline .vis-panel.vis-top,
#timeline .vis-panel.vis-bottom {
  border: 1px #5c5c5c;
}
#timeline .vis-panel.vis-top,
#timeline .vis-panel.vis-bottom,
#timeline .vis-panel.vis-left {
  border-style: solid;
}

#timeline .vis-panel.vis-top {
  border-top: 0;
}
#timeline .vis-panel.vis-bottom {
  border-bottom: 0;
}
.vis-item.vis-point.vis-selected {
  background-color: inherit !important;
  color: #e60000 !important;
  font-weight: bold;
}
.tooltip_frame {
  border: 0px;
  min-height: 300px;
  width: 100%;
}

div.vis-tooltip {
  background-color: #222 !important;
  color: #bdbdbd !important;
  border-radius: 0px !important;
  font-family: inherit !important;
  font-size: inherit !important;
  /*box-shadow: 5px 5px 3px -3px rgba(120,120,120,.3) !important;*/
  padding: 10px 20px;
  line-height: 1.5rem;
  border: 2px solid #404040 !important;
}

.info-box .MuiPaper-root {
  background-color: inherit !important;
}
.MuiGrid-item .MuiPaper-root {
  /* color: inherit !important; */
  color: #fff !important;
}
.MuiTableCell-body,
.MuiTableCell-head {
  /* color: inherit !important; */
  color: #fff !important;
  /*font-size: inherit;*/
}
.MuiTableCell-root,
.MuiTableCell-head {
  border: 0px !important;
  /*font-size: inherit !important;
  font-family: inherit !important;*/
}
.todo-list .MuiTableCell-root {
  border-bottom: 1px solid #5c5c5c !important;
}
.MuiListItemIcon-root {
  min-width: 20px !important;
}
.MuiListItem-gutters {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.MuiTableCell-sizeSmall {
  padding: 5px 0px 5px 5px !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.MuiTableCell-sizeSmall:last-child {
  padding-right: 10px !important;
  vertical-align: bottom;
}
.MuiFormControl-root {
  width: 100%;
}
.MuiIconButton-root {
  color: inherit !important;
}
.record-modal .MuiPaper-root {
  color: white !important;
  background-color: #444 !important;
  box-shadow: 5px 5px 3px -3px rgba(120, 120, 120, 0.3) !important;
}
.record-modal .MuiPaper-rounded {
  border-radius: 0 !important;
}
.record-modal .MuiFormControl-root {
  width: 100%;
  margin-bottom: 20px;
}
.record-modal .MuiInputBase-input,
.MuiFormLabel-root {
  color: white !important;
  /*font-family: inherit;
  font-size: initial;*/
}
.record-modal .MuiInputBase-input {
  margin-top: 10px;
}
.record-modal .MuiInputLabel-shrink {
  -webkit-transform: translate(0, 1.5px) !important;
          transform: translate(0, 1.5px) !important;
}
.record-modal .MuiDialogTitle-root {
  text-align: center;
}
.record-modal .MuiButton-textPrimary {
  color: white !important;
}
.record-modal .MuiButton-textSecondary {
  color: #e60000 !important;
}
.userSettings .list_companies div {
  background-color: inherit;
}
.userSettings {
  width: 100%;
}
.userSettings table {
  background-color: #222;
  color: #bdbdbd;
  /*font-family: inherit;
  font-size: inherit;*/
}
.userSettings table td,
.userSettings table th {
  border-bottom: 1px solid #5c5c5c !important;
  background-color: #222;
  /*font-family: inherit;
  font-size: inherit;*/
}

.userSettings .MuiTableCell-body,
.userSettings .MuiTableCell-head,
.userSettings .MuiTableSortLabel-root.MuiTableSortLabel-active,
.userSettings
  .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon,
.userSettings .MuiSelect-icon,
.userSettings .MuiInputBase-input {
  color: #bdbdbd;
  font-family: inherit;
  font-size: inherit;
}

.userSettings .MuiButton-textPrimary,
.userSettings .MuiTableCell-body input,
.userSettings .MuiTableCell-body select {
  color: #73b2ff;
  /*font-family: inherit;
  font-size: inherit;*/
}
.userSettings .MuiTableSortLabel-root:hover,
.userSettings .MuiSelect-selectMenu,
.userSettings .MuiButton-label,
.userSettings .MuiCheckbox-root {
  color: #73b2ff;
}

.userSettings .MuiTableCell-root,
.userSettings .MuiIconButton-root {
  padding: 4px 4px;
}

.userSettings .MuiIconButton-root {
  margin: 0 !important;
}

.head_box_table {
  width: 100%;
  padding: 0px;
  margin: 0px;
  background-color: inherit !important;
}

.head_box_heading {
  font-size: 2.5rem !important;
  font-weight: bold !important;
  text-align: center !important;
}

.head_box_table .head_box_text {
  font-size: 1.2rem !important;
  font-weight: 500;
  vertical-align: middle;
  text-align: left;
}

.head_box_table .head_box_number {
  font-size: 1.2rem !important;
  font-weight: bold;
  vertical-align: middle;
  text-align: right;
}

.head_box_table .head_box_number.red,
.head_box_table .head_box_heading.red {
  color: #e60000 !important;
}

.head_box_table .head_box_number.green,
.head_box_table .head_box_heading.green {
  color: rgb(112, 168, 0) !important;
}

.head_box_table .head_box_number.white,
.head_box_table .head_box_heading.white {
  color: #ffffff !important;
}

.update_table h6:first-child {
  margin-top: 5px;
}

.MuiTypography-h2,
.MuiTypography-h6 {
  font-weight: 400 !important;
}
.MuiTypography-h6 {
  line-height: 1 !important;
}
.ReactVirtualized__Table__headerColumn {
  text-transform: initial;
  font-weight: initial;
}
.ReactVirtualized__Table__headerColumn:focus,
.ReactVirtualized__Table__Grid:focus,
.ReactVirtualized__Grid:focus,
.ReactVirtualized__Table__row:focus {
  outline: none;
}
.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 0px;
}
.ReactVirtualized__Table__row .ReactVirtualized__Table__rowColumn:nth-child(2),
.ReactVirtualized__Table__headerRow
  .ReactVirtualized__Table__headerColumn:nth-child(2) {
  text-align: left;
}
.search-list .ReactVirtualized__Table__rowColumn{
  white-space: break-spaces;
} 
.ReactVirtualized__Table__headerRow {
  border-bottom: 1px solid #5c5c5c !important;
}
.active_headline {
  text-decoration: underline;
}
.node-wrapper:after {
  border: 0px;
}
.transaction_table {
  table-layout: fixed;
  width: 100%;
}
.transaction_table tr td:nth-child(1),
.transaction_table tr td:nth-child(3) {
  width: 20%;
}
.transaction_table tr td:nth-child(2),
.transaction_table tr td:nth-child(4) {
  width: 30%;
}
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 7px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
  z-index: 1001;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: #404040;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #404040;
  opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: #5c5c5c;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 7px;
  /* there must be 'right' for ps__thumb-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
  z-index: 100002;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 7px;
}

.active_tree{
  background-color: #ffd04b;
}


#observedNode,  #observedNode table{
  background-color: #fff !important;
  color: #000;
}

#observedNode table td{
  border:0px !important;
  background-color: #fff !important;
}
/*Tree*/
.tree td div {
  height: 24px !important;
}

#tdParentHeader {
  font-size: 16pt;
  font-family: Franklin Gothic, Helvetica;
  line-height: 16pt;
  font-weight: lighter;
  color: #023c59;
margin: 0;
padding: 4px 0 0 0;
}
  
#tdControls {
vertical-align: bottom;
text-align: right;
border: none;
}

#tblLegend td.col1 {
vertical-align: middle;
font-size: 9pt;
padding: 2px;
font-weight: bold;
width: 30px;
}

#tblLegend td.col2 {
width: 10px;
}

#tblLegend td.col3 {
vertical-align: middle;
font-size: 9pt;
padding: 2px;
border: solid 1px #666;
width: 875px;
}

#tdLegendContent span {
  padding-right: 7px;
font-size: 9pt;
}

#observedNode h1 {
font-family: calibri;
font-size: 19px;
font-weight: lighter;
color: #ed1c24;
margin: 0;
padding: 6px 0 0 0;
}

#observedNode img.legend {
height: 17px;
width: 17px;
border: none;
padding: 0 4px 0 1px;
vertical-align: bottom;
}

#observedNode span.unselected {
color:blue;
font-size: 13px;
font-family: calibri;
padding-left:5px;
}

#observedNode span.role {
color: #555;
font-size: 13px;
font-family: calibri;
}


#observedNode span.noe {text-decoration: none;color: black;font-size: 13px;font-family: calibri;}
#observedNode span.a {text-decoration: none;color: black;font-size: 13px;font-family: calibri;}
#observedNode span.a:link {text-decoration: none;color: black;font-size: 13px;font-family: calibri;}
#observedNode span.a:visited {text-decoration: none;color:black;font-size: 13px;font-family: calibri;}
#observedNode span.a:active {text-decoration: none;color: black;font-size: 13px;font-family: calibri;}


#observedNode span.location {
color: #6d6e71;
font-size: 13px;
font-weight: normal;
font-style: normal;
font-family: calibri;
}

#observedNode a,
#observedNode a:link,
#observedNode a:visited,
#observedNode a:active {
text-decoration: underline;
  font-size: 9pt;
font-weight: normal;
color: #023c59;
}

#iframeHierarchy0,
#iframeHierarchy1,
#iframeHierarchy2,
#iframeHierarchy3, 
#iframeHierarchy4,
#iframeHierarchy5,
#iframeHierarchy6,
#iframeHierarchy7 {
  width: 875;
  border: dotted 1px #023c59;
  margin: 0 0 7px 0;
  padding: 0 0 7px 0;
} 

#observedNode span.selected,
#observedNode span.selected a, 
#observedNode span.selected a:link,
#observedNode span.selected a:visited,
#observedNode span.selected a:active {
color: #fff;
background-color: #6d6e71;
font-weight: bold;
font-size: 13px;
font-family: calibri;
padding: 1px 3px 1px 3px;
text-decoration: none;
}

/*End Tree*/

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
.scrollbar-container {
  position: relative;
  height: 100%;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active,
.MuiTableSortLabel-root:hover,
.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
  color: #bdbdbd !important;
}

@media only screen and (max-width: 1140px) {
  .makeStyles-tableHeaderShort-334:first-child {
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 0;
    padding-bottom: 2px;
  }
  .makeStyles-tableHeaderShort-334:last-child {
    padding-top: 2px;
    padding-left: 0;
    padding-right: 5px;
    padding-bottom: 2px;
  }
  .makeStyles-tableCellCollapse-335:first-child {
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 0;
    padding-bottom: 10px;
  }
  .makeStyles-tableCellCollapse-335:last-child {
    padding-top: 10px;
    padding-left: 0;
    padding-right: 5px;
    padding-bottom: 10px;
  }
}
@media (min-width: 80em) and (max-width: 95em) {
  html {
    font-size: 0.85rem;
  }
}
@media (min-width: 60em) and (max-width: 79em) {
  html {
    font-size: 0.7rem;
  }
}
@media (min-width: 40em) and (max-width: 59em) {
  html {
    font-size: 0.5rem;
  }
}

@media (max-width: 39em) {
  html {
    font-size: 0.4rem;
  }
}

